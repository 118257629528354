<template>
  <div class="favq">
    <heand :tagnum="tagnum"></heand>

    <div class="enterprise clearfix">
      <div class="left">
        <ziyuannavleft />
      </div>
      <div class="right">
        <dl class="project_toptit">
          <dt>{{company_name}}</dt>
          <dd>
            <a
              class="xzeng_btn"
              @click="$router.push({name:'projectgldetsilsbianj',query:{id:id}})"
            >编辑</a>
          </dd>
        </dl>
        <ul class="proje_ul">
          <li>
            <strong>成立时间：</strong>
            <span>{{build_date}}</span>
          </li>
          <li>
            <strong>联系人：</strong>
            <span>{{admin}}</span>
          </li>
          <li>
            <strong>联系电话：</strong>
            <span>{{mobile}}</span>
          </li>
          <li>
            <strong>联系邮箱：</strong>
            <span>{{email}}</span>
          </li>
          <li>
            <strong>项目名称：</strong>
            <span>{{name}}</span>
          </li>
          <li>
            <strong>生产产品：</strong>
            <span>{{product}}</span>
          </li>
          <li>
            <strong>所在行业：</strong>
            <span>{{industry}}</span>
          </li>
          <li>
            <strong>投资金额：</strong>
            <span>{{invest}}</span>
          </li>
          <li>
            <strong>面积：</strong>
            <span>{{area}}亩</span>
          </li>
          <li>
            <strong>预计年产值：</strong>
            <span>{{value}}万元</span>
          </li>
          <li>
            <strong>预计税收：</strong>
            <span>{{tax}}</span>
          </li>
          <li>
            <strong>入库时间：</strong>
            <span>{{store}}</span>
          </li>
          <li>
            <strong>项目对接人：</strong>
            <span>{{link}}</span>
          </li>
          <li>
            <strong>对接人联系方式：</strong>
            <span>{{link_mobile}}</span>
          </li>
          <li>
            <a class="chengzi_btn" @click="gotodetails">查看企业详情</a>
          </li>
        </ul>

        <ul class="xm_dengji">
          <li>
            项目等级：
            <span>{{this.level}}</span>
          </li>
          <li>
            最新进展：
            <span>{{this.stage}}</span>
          </li>
        </ul>

        <div class="project_main">
          <dl class="project_tit">
            <dt>项目介绍</dt>
          </dl>
          <h1 class="pre_xtit">投资主体介绍：</h1>
          <div class="project_xq">{{this.main_introduce}}</div>
          <h1 class="pre_xtit">投资项目介绍：</h1>
          <div class="project_xq">{{this.invest_introduce}}</div>
          <h1 class="pre_xtit">项目建设内容：</h1>
          <div class="project_xq">{{this.build_introduce}}</div>
          <h1 class="pre_xtit">项目经济效益：</h1>
          <div class="project_xq">{{this.encomy}}</div>
          <h1 class="pre_xtit">项目政策需求：</h1>
          <div class="project_xq">{{this.policy}}</div>

          <dl class="project_tit">
            <dt>项目进展及存在的问题</dt>
          </dl>
          <div class="project_jsh">{{this.question}}</div>

          <dl class="project_tit">
            <dt>项目文件</dt>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width:5%">序号</td>
                <td style="width:50%;">文件名</td>
                <td style="width:15%">文件大小</td>
                <td style="width:10%;">发布人</td>
                <td style="width:320%;">操作</td>
              </tr>
              <tr style="width:100%" v-for="(item,index) in trtd" :key="index">
                <td style="width:5%">{{index+1}}</td>
                <td style="width:50%;">{{item.name}}</td>
                <td style="width:5%">{{item.file_size}}</td>
                <td style="width:10%;">{{item.uploader}}</td>
                <td style="width:30%;">
                  <span class="chengzi_btn" @click="getdownItemFile(item.file_id)">下载</span>
                  <span class="redzi_btn">删除</span>
                </td>
              </tr>
            </table>
          </div>

          <dl class="project_tit">
            <dt>项目动态追踪</dt>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width:25%">项目阶段</td>
                <td style="width:25%;">更新时间</td>
                <td style="width:25%">发起机构</td>
                <td>更新人</td>
              </tr>
              <tr style="width:100%" v-for="(item,index) in statetrtd" :key="index">
                <td style="width:20%">{{item.stage}}</td>
                <td style="width:30%;">{{item.time}}</td>
                <td style="width:30%">{{item.unit}}</td>
                <td style="width:15%;">{{item.updater}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { detailsItem, downItemFile } from "@/api/index";
export default {
  data() {
    return {
      tagnum: 7,
      build_date: "", //   		# 公司成立时间
      company_name: "", //   		# 项目企业
      admin: "", //  			# 联系人
      mobile: "", //   			# 联系电话
      email: "", //			# 联系邮箱
      name: "", //			# 项目名称
      store: "", // 			# 入库时间
      product: "", //  			# 生产产品
      industry: "", //  			# 行业
      indu_id: "", // 			# 所在行业id
      invest: "", // 			# 投资额(亿元)
      area: "", //   			# 占地面积（亩）
      value: "", //   			# 预计产值(亿元)
      tax: "", //  			# 预计税收(亿元)
      link: "", //   			# 对接人
      link_mobile: "", //  		# 对接人的联系方式
      level: "", //   			# 项目的级别
      level_id: "", //  			# 项目级别ID
      stage: "", //  			# 项目的进度
      stage_id: "", //  			# 项目进度id
      main_introduce: "", //  		# 项目主体介绍
      invest_introduce: "", //		# 投资项目介绍
      build_introduce: "", // 		# 项目建设内容
      encomy: "", // 			# 项目的经济效益
      policy: "", // 			# 项目政策需求
      question: "", //   			# 项目存在的问题
      id: "",
      trtd: [],
      statetrtd: [],
      company_id: ""
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.lookoredit = this.$route.query.lookoredit;
    this.getdetailsItem();
  },
  methods: {
    // 获取详情数据
    async getdetailsItem() {
      const res = await detailsItem({
        item_id: this.id
      });
      this.company_id = res.basic.company_id;
      this.build_date = res.basic.build_date; //   		# 公司成立时间
      this.company_name = res.basic.company_name; //   		# 项目企业
      this.admin = res.basic.admin; //  			# 联系人
      this.mobile = res.basic.mobile; //   			# 联系电话
      this.email = res.basic.email; //			# 联系邮箱
      this.name = res.basic.name; //			# 项目名称
      this.store = res.basic.store; // 			# 入库时间
      this.product = res.basic.product; //  			# 生产产品
      this.industry = res.basic.industry; //  			# 行业
      this.indu_id = res.basic.indu_id; // 			# 所在行业id
      this.invest = res.basic.invest; // 			# 投资额(亿元)
      this.area = res.basic.area; //   			# 占地面积（亩）
      this.value = res.basic.value; //   			# 预计产值(亿元)
      this.tax = res.basic.tax; //  			# 预计税收(亿元)
      this.link = res.basic.link; //   			# 对接人
      this.link_mobile = res.basic.link_mobile; //  		# 对接人的联系方式
      this.level = res.basic.level; //   			# 项目的级别
      this.level_id = res.basic.level_id; //  			# 项目级别ID
      this.stage = res.basic.stage; //  			# 项目的进度
      this.stage_id = res.basic.stage_id; //  			# 项目进度id
      this.main_introduce = res.basic.main_introduce; //  		# 项目主体介绍
      this.invest_introduce = res.basic.invest_introduce; //		# 投资项目介绍
      this.build_introduce = res.basic.build_introduce; // 		# 项目建设内容
      this.encomy = res.basic.encomy; // 			# 项目的经济效益
      this.policy = res.basic.policy; // 			# 项目政策需求
      this.question = res.basic.question; //   			# 项目存在的问题
      this.statetrtd = res.trends_list;
      this.trtd = res.file;
    },
    // 下载
    async getdownItemFile(id) {
      const res = await downItemFile({
        file_id: id
      });
      this.jqDownload(res.url, res.filename);
    },
    jqDownload(url, name) {
      fetch(url).then(res =>
        res.blob().then(blob => {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob);
          var filename = name;
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        })
      );
    },
    //前往详情
    gotodetails() {
      if (this.company_id == -1) {
        this.$message({
          message: "暂无企业详情",
          type: "error"
        });
      } else {
        this.$router.push({
          name: "chdetsils9",
          query: {
            id: this.company_id
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  background: rgb(243, 243, 243);

  li {
    list-style: none;
  }

  .c-top {
    width: 100%;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  margin: 1% 0;
}
.right {
  width: calc(100% - 256px - 10px);
  background: #fff;
  padding: 18px 20px 10px 20px;
  float: right;
  margin-top: 10px;

  .project_toptit {
    overflow: hidden;
    zoom: 1;
    margin-bottom: 10px;

    dt {
      float: left;
      font-size: 20px;
      color: #333;
      height: 30px;
      line-height: 30px;
    }
    dd {
      float: right;

      .xzeng_btn {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        background: #fe6a00;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        margin: 1% 5px;
        padding: 0 20px;
      }
    }
  }

  .proje_ul {
    background: #f3f6fb;
    padding: 20px 30px;
    overflow: hidden;
    zoom: 1;
    margin-bottom: 15px;
    li {
      width: 25%;
      float: left;
      font-size: 14px;
      text-align: left;
      line-height: 34px;

      strong {
        font-weight: normal;
      }
      .chengzi_btn {
        color: #fe6a00;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .xm_dengji {
    font-size: 14px;
    text-align: left;
    line-height: 34px;
    overflow: hidden;
    zoom: 1;

    li {
      margin-right: 25px;
      float: left;
    }

    span {
      color: #fe6a00;
      background: #fff0e5;
      border-radius: 3px;
      padding: 5px 35px;
    }
  }

  .project_main {
    overflow: hidden;
    zoom: 1;

    .project_tit {
      overflow: hidden;
      zoom: 1;
      margin: 15px 0 6px 0;

      dt {
        float: left;
        font-weight: bold;
        font-size: 16px;
        color: #fe6a00;
        height: 30px;
        line-height: 30px;
      }

      dt:before {
        content: " ";
        padding: 0 3px 0 0;
        margin-right: 10px;
        background: #fe6a00;
      }

      dd {
        float: right;

        .el-checkbox {
          margin-right: 15px;
        }

        .xzeng_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          background: #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #ffffff;
          margin: 1% 5px;
          padding: 0 20px;
        }

        .del_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          border: 1px solid #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #fe6a00;
          margin: 1% 5px;
          padding: 0 20px;
        }

        .xiaz_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          border: 1px solid #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #fe6a00;
          margin: 1% 5px;
          padding: 0 20px;
        }
      }
    }

    .pre_xtit {
      font-size: 14px;
      text-align: left;
      line-height: 28px;
      margin-left: 12px;
      font-weight: normal;
    }
    .pre_xtit::before {
      content: " ";
      display: block;
      float: left;
      width: 6px;
      height: 6px;
      margin-right: 8px;
      margin-top: 10px;
      background: #333;
    }

    .project_xq {
      text-align: left;
      font-size: 14px;
      line-height: 28px;
      margin-left: 27px;
      margin-bottom: 10px;
    }

    .project_jsh {
      text-align: left;
      font-size: 14px;
      margin-left: 15px;
      margin-bottom: 20px;
    }

    .project_tables {
      font-size: 14px;
      line-height: 44px;
      margin-bottom: 20px;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 2%;
      }
      .chengzi_btn {
        color: #fe6a00;
        cursor: pointer;
      }
      .lvzi_btn {
        color: #2cb54c;
      }
      .zizi_btn {
        color: #135de6;
      }
      .redzi_btn {
        color: #e51313;
        cursor: pointer;
      }
      .bluezi_btn {
        color: #0ebbf3;
      }
      .huangzi_btn {
        color: #e69554;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }

    //分页
    /deep/.el-pagination {
      float: right;
      margin-top: 20px;
    }
    /deep/.btn-prev {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.btn-next {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.el-pager li {
      border: 1px solid #888;
      margin: 0 3px;
      min-width: 27px;
    }
    /deep/.el-pager li.active,
    /deep/.el-pager li:hover {
      border: 1px solid #fe6a00;
      color: #fe6a00;
    }

    //分页end
  }
}
</style>